/**
 * LCL & AIR B/L Tracking 정책
 * B/L 입력시 갱신 가능 유형인지, 갱신 시점이 언제인지 안내 (ResponseHandler)
 */
const LCL_AND_AIR_BL_TRACKING_POLICY_MAP = {
  LCL: "LCL 운송은 B/L 데이터 연동을 통해 Tracking 을 제공하지 않습니다.",
  AIR: "항공 운송은 AWB 데이터 연동을 통해 Tracking 을 제공하지 않습니다.",
};

const FCL_BL_TRACKING_POLICY_MAP: Record<string, string> = {
  WRONG_NUMBER:
    "입력하신 BL 은 트래킹 불가능하거나, 잘못된 BL 번호입니다. MBL 이 맞는지, 오탈자는 없는지 다시 한번 확인해주세요.",
  SEALINE_HASNT_PROVIDE_INFO:
    "입력하신 BL 에 대해 선사 또는 외부 트래킹 서비스에서 정보를 정상적으로 제공하지 않아, 자동갱신이 불가능합니다. 출항완료가 임박했다면 슬랙 > 지원_포워딩 채널로 문의해주세요. ",
  UNEXPECTED_ERROR:
    "외부 트래킹 서비스에 오류가 발생하였습니다. 슬랙 > 지원_포워딩 채널로 문의해주세요.",
  NO_EVENTS:
    "입력하신 BL 에 대해 트래킹이 정상적으로 시작되었으나, 아직 이력이 존재하지 않습니다. 내일 오전에 다시 한번 조회를 시도해주세요.",
  AUTO_CANT_DETECT_SEALINE:
    "입력하신 BL 에 해당하는 선사가 외부 트래킹 서비스에서 확인되지 않습니다.",
  NO_CONTAINERS:
    "입력하신 BL 에 컨테이너가 확인되지 않습니다. 내일 오전에 다시 한번 조회해보세요.",
  SEALINE_UNDER_MAINTENCANCE:
    "입력하신 BL 을 발행한 선사의 시스템이 유지보수중입니다. 내일 다시 확인해주세요.",
  NO_CHANGE:
    "수정되었습니다. MBL / 선사가 수정되지 않았으므로 BL Tracking 은 기존대로 유지됩니다.",
  OK: "**FCL 운송은 B/L 데이터 연동을 통해 Tracking 정보가 업데이트되며, 3회 / 1일 (오전 7:30 / 오후 13:00 / 오후 17:00) 경에 갱신됩니다.",
  NO_PROD:
    "테스트환경에서는 외부 연동을 통한 Tracking 이 정상 동작하지 않습니다.",
};

export { LCL_AND_AIR_BL_TRACKING_POLICY_MAP, FCL_BL_TRACKING_POLICY_MAP };
