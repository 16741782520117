import { FieldNamesMarkedBoolean } from "react-hook-form";

import {
  UPDATE_SHIPMENT_ITEM_CARGO_REQ,
  UPDATE_SHIPMENT_ITEM_CARGO_REQ_BID_ITEM_GROUP,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import {
  AdminBidDetail,
  AdminShipmentItemGroup,
  AdminShipmentItemGroupForm,
  AdminShipmentItemGroupFormForExisting,
  ShipmentItem,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";

/**
 * input mode별 수정가능한 prop 리스트
 */
const ITEM_KEY_LIST_BY_CARGO_INPUT_MODE: Record<
  ShipmentItem["mode"] | "container",
  (keyof ShipmentItem)[]
> = (() => {
  const common: (keyof ShipmentItem)[] = ["mode", "name", "isDangerous"];

  const commonForTotalAndItem: (keyof ShipmentItem)[] = [
    ...common,
    "packingType",
    "canStack",
    "weight",
    "weightUnit",
    "volumeUnit",
  ];

  return {
    total: [
      ...commonForTotalAndItem,
      // total인 경우만 가지는 속성
      "cbm",
    ],
    item: [
      ...commonForTotalAndItem,
      // item인 경우만 가지는 속성
      "quantity",
      "horizontal",
      "vertical",
      "height",
    ],
    container: [...common],
  };
})();

/**
 * item중 dirty(수정된) 상태면서 update가능한 prop들만 담아 return
 */
function getItemWithDirtyAndUpdatablePropOnly({
  item,
  itemDirtyFields,
  cargoInputMode,
  isFCL,
}: {
  item: Partial<ShipmentItem>;
  itemDirtyFields: FieldNamesMarkedBoolean<ShipmentItem> | undefined;
  cargoInputMode: ShipmentItem["mode"];
  isFCL: boolean;
}): Partial<ShipmentItem> {
  return getItemWithUpdatablePropOnly({
    item: getItemWithDirtyPropOnly({ item, itemDirtyFields }),
    cargoInputMode,
    isFCL,
  });
}

/**
 * item중 dirty(수정된)인 prop들만 담아 return
 */
function getItemWithDirtyPropOnly({
  item,
  itemDirtyFields,
}: {
  item: Partial<ShipmentItem>;
  itemDirtyFields: FieldNamesMarkedBoolean<ShipmentItem> | undefined;
}): Partial<ShipmentItem> {
  let result: Partial<ShipmentItem> = {};

  if (!item || isEmptyObjectOrArray(item)) {
    return result;
  }

  if (!itemDirtyFields || isEmptyObjectOrArray(itemDirtyFields)) {
    return result;
  }

  /**
   * dirty된 상태인 key들만 가져와 result에 추가한다.
   * 이 방식으로 하면 객체 내부 1depth 까지 밖에 체크되지 않으나 현재 데이터 구조에서는 1depth만 체크해도 충분하다.
   */
  let dirtyFieldKey: keyof ShipmentItem;
  for (dirtyFieldKey in itemDirtyFields) {
    if (dirtyFieldKey in item && itemDirtyFields[dirtyFieldKey] === true) {
      result = { ...result, [dirtyFieldKey]: item[dirtyFieldKey] };
    }
  }

  return result;
}

/**
 * item중 update가능한 prop들만 담아 return
 * - item에는 수정가능한 속성들만 포함되어야한다.
 */
function getItemWithUpdatablePropOnly({
  item,
  isFCL,
  cargoInputMode,
}: {
  item: Partial<ShipmentItem>;
  cargoInputMode: ShipmentItem["mode"];
  isFCL: boolean;
}): Partial<ShipmentItem> {
  let result: Partial<ShipmentItem> = {};

  if (!cargoInputMode) {
    return result;
  }
  const updatablePropList = isFCL
    ? ITEM_KEY_LIST_BY_CARGO_INPUT_MODE["container"]
    : ITEM_KEY_LIST_BY_CARGO_INPUT_MODE[cargoInputMode];

  /**
   * 이 방식으로 하면 객체 내부 1depth 까지 밖에 체크되지 않으나 현재 데이터 구조에서는 1depth만 체크해도 충분하다.
   */
  let itemPropKey: keyof ShipmentItem;
  for (itemPropKey in item) {
    const isUpdatableProp = updatablePropList.includes(itemPropKey);

    if (isUpdatableProp && itemPropKey in item) {
      result = { ...result, [itemPropKey]: item[itemPropKey] };
    }
  }

  return result;
}

function getItemIdListFromItemGroupList({
  itemGroupList,
  isFCL,
}: {
  itemGroupList: AdminShipmentItemGroup[];
  isFCL: boolean;
}) {
  if (isFCL) {
    // FCL은 N컨테이너므로 container내부의 id들을 모두 집계
    return itemGroupList.reduce((a: number[], c) => {
      const subList = c.containerInfo?.map((item) => item.id) || [];

      return [...a, ...subList];
    }, []);
  }

  return itemGroupList.map(({ item }) => item.id);
}

function getRequestPayload({
  consolidationExporterGroupId,
  shipmentDetail,
  cargoInputMode,
  itemGroupList,
  dirtyFields,
}: {
  shipmentDetail: AdminBidDetail;
  consolidationExporterGroupId?: number;
  cargoInputMode: ShipmentItem["mode"];
  itemGroupList: AdminShipmentItemGroupForm[];
  dirtyFields: {
    itemGroupList?: FieldNamesMarkedBoolean<AdminShipmentItemGroupForm>[];
  };
}) {
  /**
   * FCL는 N컨테이너가 적용되므로 FCL여부에 따라 containerInfo 관련 payload가 달라진다.
   */
  const isFCL = shipmentDetail.freightType === "FCL";

  /**
   * 수정 전 item 리스트
   */
  const originalShipmentItemList =
    shipmentDetail.serviceType === "consolidation"
      ? shipmentDetail.itemGroupList.filter(
          ({ item }) => item.exporterGroup === consolidationExporterGroupId
        )
      : shipmentDetail.itemGroupList;

  /**
   * 수정 전 item id 리스트
   */
  const originalItemIdList: number[] = getItemIdListFromItemGroupList({
    itemGroupList: originalShipmentItemList,
    isFCL,
  });

  /**
   * 수정 후에도 남아있는 item id 목록
   */
  const remainedItemIdList: number[] = (() => {
    const existingItemGroupList = itemGroupList.filter(
      /** 새로 추가된 경우(mode를 변경한 경우 포함) id가 없으므로 기존에 있었던 것들만 체크 */
      (v): v is AdminShipmentItemGroupFormForExisting => v.type === "existing"
    );

    return getItemIdListFromItemGroupList({
      itemGroupList: existingItemGroupList,
      isFCL,
    });
  })();

  /**
   * 수정 후 삭제될 item id 목록
   */
  const deletedItemIdList: number[] = originalItemIdList.filter(
    (value): value is number => {
      return !!value && !remainedItemIdList.includes(value);
    }
  );

  /**
   * 수정 or 새로 추가되는 itemGroupList
   */
  const updatedItemGroupList = itemGroupList.reduce(
    (
      a: NonNullable<UPDATE_SHIPMENT_ITEM_CARGO_REQ["itemGroupList"]>,
      c,
      itemGroupIndex
    ) => {
      if (c.type === "new") {
        const newItemGroup: UPDATE_SHIPMENT_ITEM_CARGO_REQ_BID_ITEM_GROUP = {
          // 새로 추가된 것은 모든 값을 통째로 추가
          item: getItemWithUpdatablePropOnly({
            item: c.item,
            cargoInputMode,
            isFCL,
          }),
          itemGroup: itemGroupIndex + 1,
          ...(isFCL
            ? {
                containerInfo:
                  c.containerInfo as UPDATE_SHIPMENT_ITEM_CARGO_REQ_BID_ITEM_GROUP["containerInfo"],
              }
            : {}),
        };

        return [...a, newItemGroup];
      }

      if (c.type === "existing") {
        if (isEmptyObjectOrArray(dirtyFields)) {
          return a;
        }

        const itemGroupDirtyFields =
          dirtyFields.itemGroupList?.[itemGroupIndex] || {};
        if (isEmptyObjectOrArray(itemGroupDirtyFields)) {
          return a;
        }

        const updatedItemGroup: UPDATE_SHIPMENT_ITEM_CARGO_REQ_BID_ITEM_GROUP | null =
          (() => {
            const result: Omit<
              UPDATE_SHIPMENT_ITEM_CARGO_REQ_BID_ITEM_GROUP,
              "itemGroup"
            > = {};

            if (!isEmptyObjectOrArray(itemGroupDirtyFields.item || {})) {
              const itemWithDirtyAndUpdatablePropOnly =
                getItemWithDirtyAndUpdatablePropOnly({
                  item: c.item,
                  itemDirtyFields: itemGroupDirtyFields.item,
                  cargoInputMode,
                  isFCL,
                });
              if (!isEmptyObjectOrArray(itemWithDirtyAndUpdatablePropOnly)) {
                result.item = {
                  ...itemWithDirtyAndUpdatablePropOnly,
                  // 이하 item이 변경된 경우 포함되어야할 필수 값
                  id: c.item.id,
                  mode: c.item.mode,
                };
                if (isFCL) {
                  /**
                   * item이 수정된 경우도 container 정보를 보냄
                   * - containerInfo는 항상 현재 상태를 보내므로, 보내지 않으면 삭제로 간주됨
                   */
                  result.containerInfo = c.containerInfo;
                }
              }
            }

            if (
              isFCL &&
              !isEmptyObjectOrArray(itemGroupDirtyFields.containerInfo || [])
            ) {
              /**
               * container의 변경 내용이 있다면 전체 container 정보를 보냄
               * - containerInfo는 항상 현재 상태를 보내므로, 세부 변경내역을 체크하지않고 전체 정보를 보냄.
               */
              result.containerInfo = c.containerInfo;

              if (!result.item) {
                /**
                 * 화물 정보 수정 없이 컨테이너 정보만 추가하는 경우,
                 * 현재 DB상황에서는 itemGroup정보만으로 업데이트하기 어려운 상황이라 item.id 도 보내줌. (이후 DB상황이 바뀌면 다시 정리하기로 함)
                 * - 첫번째 container를 삭재한 경우 item.id를 보내는 것이 어색하나 update에는 상관없다고 함.
                 */
                result.item = { id: c.item.id, mode: c.item.mode };
              }
            }

            if (isEmptyObjectOrArray(result)) {
              return null;
            }

            return { ...result, itemGroup: itemGroupIndex + 1 };
          })();

        if (updatedItemGroup) {
          return [...a, updatedItemGroup];
        }
      }

      return a;
    },
    []
  );

  return { deletedItemIdList, updatedItemGroupList };
}

export { getItemIdListFromItemGroupList, getRequestPayload };
