import { ChangeEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { AdminShipmentInfoForm } from "@sellernote/_shared/src/types/forwarding/adminBid";

import RadioGroupWithReactHookForm from "../../../../../../../components/RadioGroupWithReactHookForm";

import {
  cfsReceivingRadioGroupOptionList,
  containerDevanningRadioGroupOptionList,
  containerStuffingRadioGroupOptionList,
  inlandTransportTypeRadioGroupOptionList,
} from "../../constants";

function FCLCheckpoint() {
  const { control, setValue } = useFormContext<AdminShipmentInfoForm>();

  const cfsReceiving = useWatch({
    control,
    name: "cfsReceiving",
  });

  const containerStuffing = useWatch({
    control,
    name: "containerStuffing",
  });

  const incoterms = useWatch({
    control,
    name: "incoterms",
  });

  return (
    <Box>
      <Box>
        <Typography variant="subtitle2">컨테이너 적입</Typography>

        <RadioGroupWithReactHookForm
          name={"containerStuffing"}
          control={control}
          required={true}
          radioGroupOption={containerStuffingRadioGroupOptionList}
          handleEffectOnChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value === "TRUE") {
              setValue("cfsReceiving", "direct", { shouldDirty: true });
              setValue("inlandTransportType", "none", { shouldDirty: true });
              return;
            }
            setValue("cfsReceiving", "shipda", { shouldDirty: true });
            setValue("inlandTransportType", "sole", { shouldDirty: true });
            return;
          }}
        />
      </Box>

      <Box>
        <Typography variant="subtitle2">CFS 입고</Typography>

        <RadioGroupWithReactHookForm
          name={"cfsReceiving"}
          control={control}
          required={true}
          disabled={containerStuffing === "FALSE"}
          radioGroupOption={cfsReceivingRadioGroupOptionList}
          handleEffectOnChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value === "direct") {
              setValue("inlandTransportType", "none", { shouldDirty: true });
              return;
            }
            setValue("inlandTransportType", "sole", { shouldDirty: true });
            return;
          }}
        />
      </Box>

      <Box>
        <Typography variant="subtitle2">운송방식</Typography>

        <RadioGroupWithReactHookForm
          name={"inlandTransportType"}
          control={control}
          required={true}
          disabled={cfsReceiving === "direct" && containerStuffing === "TRUE"}
          radioGroupOption={inlandTransportTypeRadioGroupOptionList.filter(
            (option) => {
              if (cfsReceiving === "shipda") {
                return option.value !== "none";
              }
              return option;
            }
          )}
        />
      </Box>

      {(incoterms === "DDP" || incoterms === "DAP") && (
        <Box>
          <Typography variant="subtitle2">컨테이너 적출</Typography>

          <RadioGroupWithReactHookForm
            name={"containerDevanning"}
            control={control}
            required={true}
            radioGroupOption={containerDevanningRadioGroupOptionList}
          />
        </Box>
      )}
    </Box>
  );
}

export default FCLCheckpoint;
