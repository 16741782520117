import { createContext, useState } from "react";
import { Box } from "@mui/material";

import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import CargoReadySchedule from "./CargoReadySchedule";
import ACTIVE_STATUS_LIST from "./constants";
import ScheduleForm from "./ScheduleForm";

/** 운송 상태가 '입항완료' 이후 폼, 저장/메일발송 버튼 비활성화 */
export const FormDisabledContext = createContext(false);

export default function Schedule({
  trelloDetail,
}: {
  trelloDetail: TrelloBidDetail;
}) {
  const [cargoReady, setCargoReady] = useState(
    trelloDetail.management.cargoReady
  );

  const isCargoReadyChanged = trelloDetail.management.cargoReady !== cargoReady;

  const isConsolOrLCL =
    trelloDetail.serviceType === "consolidation" ||
    trelloDetail.freightType === "LCL";

  const isImport = trelloDetail.isImport;

  /**
   * 선사나 마린트래픽이 아닌, 어드민 사용자가 입력한 변경 사유
   * 값이 존재한다면 메일 발송시 사유를 선택하지 않고, 해당 사유를 사용
   */
  const changeReasonByAdmin = trelloDetail.management.scheduleChangeReason;

  return (
    <FormDisabledContext.Provider
      value={!ACTIVE_STATUS_LIST.includes(trelloDetail.projectStatus)}
    >
      <Box sx={{ marginTop: "12px", display: "flex", flexDirection: "column" }}>
        <CargoReadySchedule
          cargoReady={cargoReady}
          setCargoReady={setCargoReady}
          shipmentId={trelloDetail.id}
          isCargoReadyChanged={isCargoReadyChanged}
        />

        <ScheduleForm
          shipmentId={trelloDetail.id}
          freightType={trelloDetail.freightType}
          isFirstScheduleEmailSend={
            trelloDetail.management.firstScheduleEmailSent
          }
          projectStatus={trelloDetail.projectStatus}
          isConsolOrLCL={isConsolOrLCL}
          isImport={isImport}
          changeReasonByAdmin={changeReasonByAdmin}
        />
      </Box>
    </FormDisabledContext.Provider>
  );
}
