import { ChangeEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { AdminShipmentInfoForm } from "@sellernote/_shared/src/types/forwarding/adminBid";

import RadioGroupWithReactHookForm from "../../../../../../../components/RadioGroupWithReactHookForm";

import {
  inclusionConditionGroupOptionList,
  inlandTransportTypeRadioGroupOptionList,
} from "../../constants";

function LCLCheckpoint() {
  const { control, setValue } = useFormContext<AdminShipmentInfoForm>();

  const cfsReceiving = useWatch({
    control,
    name: "cfsReceiving",
  });

  const incoterms = useWatch({
    control,
    name: "incoterms",
  });

  return (
    <Box>
      <Box>
        <Typography variant="subtitle2">CFS 입고</Typography>

        <RadioGroupWithReactHookForm
          name={"cfsReceiving"}
          control={control}
          required={true}
          radioGroupOption={inclusionConditionGroupOptionList}
          handleEffectOnChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value === "direct") {
              setValue("inlandTransportType", "none", { shouldDirty: true });
              return;
            }
            setValue("inlandTransportType", "sole", { shouldDirty: true });
            return;
          }}
          disabled={
            (incoterms === "DDP" || incoterms === "DAP") &&
            cfsReceiving === "shipda"
          }
        />
      </Box>

      <Box>
        <Typography variant="subtitle2">운송방식</Typography>

        <RadioGroupWithReactHookForm
          name={"inlandTransportType"}
          control={control}
          required={true}
          disabled={cfsReceiving === "direct"}
          radioGroupOption={inlandTransportTypeRadioGroupOptionList.filter(
            (option) => {
              if (cfsReceiving === "shipda") {
                return option.value !== "none";
              }
              return option;
            }
          )}
        />
      </Box>
    </Box>
  );
}

export default LCLCheckpoint;
