import { Box } from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { RealTimeTransportInfo } from "@sellernote/_shared/src/types/forwarding/bid";
import ShipmentInfographic from "@sellernote/_shared-for-forwarding/src/components/ShipmentInfographic";
import useShipmentInfographicInfo from "@sellernote/_shared-for-forwarding/src/components/ShipmentInfographic/hooks/useShipmentInfographicInfo";
import ShipmentTransferInfo from "@sellernote/_shared-for-forwarding/src/components/ShipmentTransferInfo";

export default function GraphicPreviewModalBody({
  projectStatus,
  freightType,
  shipmentId,
}: {
  projectStatus: BidProjectStatus;
  freightType: FreightType;
  shipmentId: number;
}) {
  const { data: infographicData } = TRELLO_BID_QUERY.useGetInfographic({
    shipmentId,
  });

  const {
    departure,
    departureTime,
    arrive,
    arriveTime,
    currentTransportName,
    currentTransportNumber,
  } = useShipmentInfographicInfo({
    shipmentProjectStatus: projectStatus,
    arrivalTime: (infographicData?.ATA || infographicData?.ETA) ?? undefined,
    departureTime: (infographicData?.ATD || infographicData?.ETD) ?? undefined,
    freightType: freightType,
    transportName: infographicData?.shipName ?? undefined,
    transportNumber: infographicData?.voyageNumber ?? undefined,
  });

  const realtimeTransportInfo: RealTimeTransportInfo = {
    departure,
    departureTime,
    arrive,
    arriveTime,
    freightType,
    currentTransportName,
    currentTransportNumber,
  };

  if (!infographicData) return null;

  const { transferShipment = [], startPort, endPort } = infographicData;

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: 500,
        }}
      >
        <ShipmentTransferInfo
          // 쉽다웹에서는 맵이 보이는조건에 인포그래픽이 노출되므로 어드민에서는 항상 보이도록 true값 전달 (isActiveInfo)
          isActiveInfo
          freightType={freightType}
          realtimeTransportInfo={realtimeTransportInfo}
          transferList={infographicData?.shipList}
          currentShipName={infographicData?.shipName}
        />

        <ShipmentInfographic
          freightType={freightType}
          realtimeTransportInfo={realtimeTransportInfo}
          transferShipmentList={transferShipment}
          startPort={startPort}
          endPort={endPort}
        />
      </Box>
    </>
  );
}
