import { useContext } from "react";
import { Box, Typography } from "@mui/material";

import DatePicker from "../../../../../../../components/DatePicker";

import { FormDisabledContext } from "..";
import SaveAndEmailButton from "./SaveAndEmailButton";

export default function CargoReadySchedule({
  cargoReady,
  setCargoReady,
  shipmentId,
  isCargoReadyChanged,
}: {
  cargoReady: string | null;
  setCargoReady: (val: string | null) => void;
  shipmentId: number;
  isCargoReadyChanged: boolean;
}) {
  const isDisabled = useContext(FormDisabledContext);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid #7e7e7e",
        padding: "20px",
      }}
    >
      <Typography>화물준비일정(Cargo Ready)을 입력하세요.</Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "24px",
          marginTop: "12px",
        }}
      >
        <Typography variant="h6">카고레디</Typography>

        <DatePicker
          value={cargoReady}
          setDate={setCargoReady}
          when={"start"}
          fullWidth
          disabled={isDisabled}
        />
      </Box>

      <SaveAndEmailButton
        cargoReady={cargoReady}
        shipmentId={shipmentId}
        isCargoReadyChanged={isCargoReadyChanged}
      />
    </Box>
  );
}
