import { useFormContext } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { AdminShipmentInfoForm } from "@sellernote/_shared/src/types/forwarding/adminBid";

import RadioGroupWithReactHookForm from "../../../../../../../components/RadioGroupWithReactHookForm";

import { inclusionConditionGroupOptionList } from "../../constants";

function LCLCheckpoint() {
  const { control } = useFormContext<AdminShipmentInfoForm>();

  return (
    <Box>
      <Box>
        <Typography variant="subtitle2">LSS</Typography>

        <RadioGroupWithReactHookForm
          name={"containLss"}
          control={control}
          radioGroupOption={inclusionConditionGroupOptionList}
          required={true}
        />
      </Box>

      <Box>
        <Typography variant="subtitle2">Ocean Surcharge</Typography>

        <RadioGroupWithReactHookForm
          name={"containOceanSurcharge"}
          control={control}
          radioGroupOption={inclusionConditionGroupOptionList}
          required={true}
        />
      </Box>

      <Box>
        <Typography variant="subtitle2">국내부대비용</Typography>

        <RadioGroupWithReactHookForm
          name={"containDomesticFee"}
          control={control}
          radioGroupOption={inclusionConditionGroupOptionList}
          required={true}
        />
      </Box>

      {/* TODO: 오션티켓인 경우 "containsWarehouseFee" 가 있어야 API 인터페이스에 없음 논의 후 추가 */}
    </Box>
  );
}

export default LCLCheckpoint;
