import { useFormContext, useWatch } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { AdminShipmentInfoForm } from "@sellernote/_shared/src/types/forwarding/adminBid";

import RadioGroupWithReactHookForm from "../../../../../../../components/RadioGroupWithReactHookForm";

import {
  cfsReceivingRadioGroupOptionList,
  inlandTransportTypeRadioGroupOptionList,
} from "../../constants";

function AIRCheckpoint() {
  const { control, setValue } = useFormContext<AdminShipmentInfoForm>();

  const incoterms = useWatch({
    control,
    name: "incoterms",
  });

  const cfsReceiving = useWatch({
    control,
    name: "cfsReceiving",
  });

  const containerStuffing = useWatch({
    control,
    name: "containerStuffing",
  });

  return (
    <Box>
      <Box>
        <Typography variant="subtitle2">터미널 입고</Typography>

        <RadioGroupWithReactHookForm
          name={"cfsReceiving"}
          control={control}
          required={true}
          radioGroupOption={cfsReceivingRadioGroupOptionList}
          handleEffectOnChange={() => {
            const allowsIncoterms = ["CIP", "CPT"];
            if (
              !incoterms ||
              !allowsIncoterms.includes(incoterms) ||
              containerStuffing
            )
              return;
            const inlandTransportValue =
              cfsReceiving === "shipda" ? "none" : "sole";
            setValue("inlandTransportType", inlandTransportValue, {
              shouldDirty: true,
            });
          }}
        />
      </Box>

      <Box>
        <Typography variant="subtitle2">컨테이너</Typography>

        <RadioGroupWithReactHookForm
          name={"inlandTransportType"}
          control={control}
          required={true}
          disabled={cfsReceiving === "direct"}
          radioGroupOption={inlandTransportTypeRadioGroupOptionList.filter(
            (option) => {
              if (cfsReceiving === "shipda") {
                return option.value !== "none";
              }
              return option;
            }
          )}
        />
      </Box>
    </Box>
  );
}

export default AIRCheckpoint;
