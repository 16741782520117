import { useMemo } from "react";
import { Box } from "@mui/material";
import { BackTop } from "antd";

import { GET_ADMIN_BID_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import { GET_CUSTOMS_ADMIN_BID_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/customsAdminBid";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";

import Layout from "../../../containers/Layout";

import ShipmentTable from "../ShipmentTable";

/**
 * 쉽다 어드민과 파트너 어드민에서 선적계획의뢰 테이블를 같이 사용하기 위한 커스텀 훅
 */
export default function useAdminShipmentTable({
  bidListData,
  adminUserList,
  adminDataForTableFilter,
  TablePanel,
  TableHeadPanel,
  pagination,
}: {
  bidListData:
    | GET_ADMIN_BID_LIST_RES
    | GET_CUSTOMS_ADMIN_BID_LIST_RES
    | undefined;
  adminUserList: ForwardingAdminUserListItem[] | undefined;
  adminDataForTableFilter:
    | {
        text: string;
        value: number;
      }[]
    | undefined;
  TablePanel: JSX.Element;
  TableHeadPanel: {
    ForwardingManagerFilter: JSX.Element;
    FreightAndIncotermsHeadFilter: JSX.Element;
    AllResetButton: JSX.Element;
  };
  pagination: {
    rowsPerPageOptions: number[];
    totalCount: number;
    perPage: number;
    setPerPage: (value: number) => void;
    currentPage: number;
    setCurrentPage: (value: number) => void;
  };
}) {
  const AdminShipmentTable = useMemo(() => {
    if (!bidListData || !adminUserList || !adminDataForTableFilter) {
      return (
        <Layout title={"선적계획 의뢰"}>
          <Loading active={true} />
        </Layout>
      );
    }

    return (
      <Layout title={"선적계획 의뢰"}>
        <BackTop />

        <Box sx={{ p: 1, pt: 1.5, background: "#fff" }}>{TablePanel}</Box>

        <Box sx={{ p: 1, background: "#fff" }}>
          <ShipmentTable
            adminFilterArray={adminDataForTableFilter}
            adminData={adminUserList}
            bidList={bidListData.list}
            TableHeadPanel={TableHeadPanel}
            pagination={pagination}
          />
        </Box>
      </Layout>
    );
  }, [
    adminDataForTableFilter,
    adminUserList,
    bidListData,
    TablePanel,
    TableHeadPanel,
    pagination,
  ]);

  return { AdminShipmentTable };
}
