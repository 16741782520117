import { FreightType } from "@sellernote/_shared/src/types/common/common";

import AIRCheckpoint from "./AIRCheckpoint";
import FCLCheckpoint from "./FCLCheckpoint";
import LCLCheckpoint from "./LCLCheckpoint";

function ImportCheckPoint({ freightType }: { freightType: FreightType }) {
  if (freightType === "FCL") return <FCLCheckpoint />;

  if (freightType === "LCL") return <LCLCheckpoint />;

  if (freightType === "AIR") return <AIRCheckpoint />;

  return null;
}

export default ImportCheckPoint;
