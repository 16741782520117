import { useCallback, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { GET_ADMIN_ZONE_ADDRESS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminZoneAddress";
import { FreightType, Port } from "@sellernote/_shared/src/types/common/common";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { BidTransportType } from "@sellernote/_shared/src/types/forwarding/bid";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";
import RadioGroupWithReactHookForm, {
  RadioGroupWithReactHookFormOption,
} from "@sellernote/_shared-for-forwarding-admin/src/components/RadioGroupWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

import { getAutoCompleteValidate, getPortNameOptionList } from "../utils";

import { inlandTypeRadioGroupOptions } from "../constants";

function Import({
  endAddress,
  isCTypeIncoterms,
  zoneNameListOptions,
  zoneAddress,
  startTypeRadioGroupOption,
  endTypeRadioGroupOption,
  portList,
  freightType,
  shipmentDetail,
}: {
  endAddress: string | undefined;
  isCTypeIncoterms: boolean;
  zoneNameListOptions: string[];
  zoneAddress: GET_ADMIN_ZONE_ADDRESS_RES | undefined;
  startTypeRadioGroupOption: RadioGroupWithReactHookFormOption<BidTransportType>[];
  endTypeRadioGroupOption: RadioGroupWithReactHookFormOption<BidTransportType>[];
  portList: Port[];
  freightType: FreightType;
  shipmentDetail: AdminBidDetail;
}) {
  const { control, setValue } = useFormContext();

  const [prevEndAddress, setPrevEndAddress] = useState(endAddress);

  const startType = useWatch({ control, name: "startType" });

  const endType = useWatch({ control, name: "endType" });

  const zoneId = useWatch({ control, name: "zoneId" });

  const {
    endType: shipmentEndType,
    startType: shipmentStartType,
    endPortId: shipmentEndPortId,
    endViaPortId: shipmentEndViaPortId,
    zoneId: shipmentZoneId,
    endAddress: shipmentEndAddress,
    endAddressDetail: shipmentEndAddressDetail,
    startAddress: shipmentStartAddress,
    startPortId: shipmentStartPortId,
    startViaPortId: shipmentStartViaPortId,
    inlandType: shipmentInlandType,
  } = shipmentDetail;

  /** 도착지 주소 입력 시 해당 주소가 가지고 있는 zoneId를 등록 */
  const TransportSectionRef = useCallback(
    (node) => {
      if (!node || endAddress === prevEndAddress) return;
      setPrevEndAddress(endAddress);
      if (zoneAddress && zoneAddress.length > 0) {
        setValue(
          "zoneId",
          zoneAddress?.find((v) => {
            return (
              v.roadAddress === endAddress || v.jibunAddress === endAddress
            );
          })?.id,
          { shouldDirty: true }
        );
      }
    },
    [endAddress, prevEndAddress, setValue, zoneAddress]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      ref={TransportSectionRef}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1" fontWeight={700}>
          출발지
        </Typography>

        <RadioGroupWithReactHookForm
          name="startType"
          control={control}
          required={true}
          radioGroupOption={startTypeRadioGroupOption}
          handleEffectOnChange={(e) => {
            // 출발지 타입에 따라 필요한 값만 추출하기 위해 shouldDirty를 사용
            const isInlandShipment = shipmentStartType === "inland";

            if (e.target.value !== "inland") {
              setValue("startAddress", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("startViaPortId", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("startPortId", shipmentStartPortId, {
                shouldDirty: isInlandShipment,
              });
              return;
            }

            if (e.target.value === "inland") {
              setValue("startPortId", null, { shouldDirty: isInlandShipment });
              setValue("startAddress", shipmentStartAddress, {
                shouldDirty: !isInlandShipment,
              });
              setValue("startViaPortId", shipmentStartViaPortId, {
                shouldDirty: !isInlandShipment,
              });
              return;
            }
          }}
        />

        {startType === "inland" && (
          <TextFieldWithReactHookForm
            name={"startAddress"}
            control={control}
            required={true}
            placeholder="출발지 주소"
          />
        )}

        {startType !== "inland" && (
          <AutoCompleteWithReactHookForm
            name={"startPortId"}
            control={control}
            label="항구/공항 선택"
            options={getPortNameOptionList({
              transportType: startType,
              portList: portList,
              freightType,
              isExport: false,
              isEndPort: false,
            })}
            required={true}
            validate={{ isSelect: (value) => getAutoCompleteValidate(value) }}
          />
        )}

        {startType === "inland" && !isCTypeIncoterms && (
          <AutoCompleteWithReactHookForm
            name={"startViaPortId"}
            control={control}
            label="항구/공항 선택"
            options={getPortNameOptionList({
              transportType: startType,
              portList: portList,
              freightType,
              isExport: false,
              isEndPort: false,
            })}
            required={true}
            validate={{ isSelect: (value) => getAutoCompleteValidate(value) }}
          />
        )}
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle1" fontWeight={700}>
          도착지
        </Typography>

        <RadioGroupWithReactHookForm
          name="endType"
          control={control}
          required={true}
          radioGroupOption={endTypeRadioGroupOption}
          handleEffectOnChange={(e) => {
            // 도착지 타입에 따라 필요한 값만 추출하기 위해 shouldDirty를 사용
            const isInlandShipment = shipmentEndType === "inland";

            if (e.target.value !== "inland") {
              setValue("endAddress", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("endViaPortId", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("endAddressDetail", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("zoneId", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("endPortId", shipmentEndPortId, {
                shouldDirty: isInlandShipment,
              });
              setValue("inlandType", null, {
                shouldDirty: !isInlandShipment,
              });
              return;
            }

            if (e.target.value === "inland") {
              setValue("endPortId", null, { shouldDirty: isInlandShipment });
              setValue("endAddress", shipmentEndAddress, {
                shouldDirty: !isInlandShipment,
              });
              setValue("endViaPortId", shipmentEndViaPortId, {
                shouldDirty: !isInlandShipment,
              });
              setValue("zoneId", shipmentZoneId, {
                shouldDirty: !isInlandShipment,
              });
              setValue("endAddressDetail", shipmentEndAddressDetail, {
                shouldDirty: !isInlandShipment,
              });
              setValue(
                "inlandType",
                // FCL에서는 독차가 고정
                freightType === "FCL" ? "sole" : shipmentInlandType,
                {
                  shouldDirty: !isInlandShipment,
                }
              );
              return;
            }
          }}
        />

        {endType !== "inland" && !isCTypeIncoterms && (
          <AutoCompleteWithReactHookForm
            name={"endPortId"}
            control={control}
            label="항구/공항 선택"
            options={getPortNameOptionList({
              transportType: endType,
              portList: portList,
              freightType,
              isExport: false,
              isEndPort: true,
            })}
            required={true}
            validate={{ isSelect: (value) => getAutoCompleteValidate(value) }}
          />
        )}

        {endType === "inland" && (
          <AutoCompleteWithReactHookForm
            name={"endViaPortId"}
            control={control}
            label="항구/공항 선택"
            options={getPortNameOptionList({
              transportType: endType,
              portList: portList,
              freightType,
              isExport: false,
              isEndPort: true,
            })}
            required={true}
            validate={{ isSelect: (value) => getAutoCompleteValidate(value) }}
          />
        )}

        {endType === "inland" && (
          <AutoCompleteWithReactHookForm
            name={"endAddress"}
            control={control}
            label="도착지 주소"
            options={zoneNameListOptions}
            required={true}
            /** 주소를 선택하지 않고 입력만 하면 zoneId가 생성되지 않는다. */
            validate={{
              isSelect: (value) => {
                const hasZoneId = Boolean(
                  zoneAddress?.find((v) => {
                    return v.roadAddress === value || v.jibunAddress === value;
                  })?.id
                );

                /** 쉽다-웹 등록 주소와 어드민 API 주소가 정확히 일치하지 않아 zoneId 유무를 추가 확인 */
                return !!zoneId || hasZoneId || "주소를 선택하지 않았습니다.";
              },
            }}
          />
        )}

        {endType === "inland" && (
          <TextFieldWithReactHookForm
            name={"endAddressDetail"}
            control={control}
            placeholder="상세주소"
            required={true}
          />
        )}

        {endType === "inland" && (
          <RadioGroupWithReactHookForm
            name="inlandType"
            control={control}
            disabled={freightType === "FCL"}
            required={true}
            radioGroupOption={inlandTypeRadioGroupOptions}
          />
        )}
      </Box>
    </Box>
  );
}

export default Import;
