import {
  BooleanStringV2,
  InlnadTransportType,
} from "@sellernote/_shared/src/types/common/common";
import {
  CfsReceiving,
  InlandTransportType,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { BidTransportType } from "@sellernote/_shared/src/types/forwarding/bid";

import { RadioGroupWithReactHookFormOption } from "../../../../../components/RadioGroupWithReactHookForm";

const inclusionConditionGroupOptionList: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "포함",
      value: "TRUE",
    },
    {
      label: "미포함",
      value: "FALSE",
    },
  ];

const containerAccessableRadioGroupOptionList: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "가능",
      value: "TRUE",
    },
    {
      label: "불가능",
      value: "FALSE",
    },
  ];

const transPortTypeRadioGroupOptionList: RadioGroupWithReactHookFormOption<BidTransportType>[] =
  [
    {
      label: "내륙",
      value: "inland",
    },
    {
      label: "해상",
      value: "sea",
    },
    {
      label: "항공",
      value: "air",
    },
  ];

const cfsReceivingRadioGroupOptionList: RadioGroupWithReactHookFormOption<CfsReceiving>[] =
  [
    {
      label: "직접(고객사)",
      value: "direct",
    },
    {
      label: "대행(쉽다)",
      value: "shipda",
    },
  ];

const containerStuffingRadioGroupOptionList: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "직접(고객사)",
      value: "TRUE",
    },
    {
      label: "대행(쉽다)",
      value: "FALSE",
    },
  ];

const containerDevanningRadioGroupOptionList: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "신청",
      value: "TRUE",
    },
    {
      label: "미신청",
      value: "FALSE",
    },
  ];

const inlandTransportTypeRadioGroupOptionList: RadioGroupWithReactHookFormOption<InlandTransportType>[] =
  [
    {
      label: "독차",
      value: "sole",
    },
    {
      label: "합차",
      value: "consol",
    },
    {
      label: "해당 없음",
      value: "none",
    },
  ];

const exportCheckpointList = [
  "cfsReceiving",
  "containerStuffing",
  "inlandTransportType",
  "containerDevanning",
  "exportCustoms",
  "useVGM",
];

const importCheckpointList = [
  "containerAccessable",
  "containDomesticFee",
  "containOceanSurcharge",
  "containLss",
];

const inlandTypeRadioGroupOptions: RadioGroupWithReactHookFormOption<InlnadTransportType>[] =
  [
    {
      label: "독차",
      value: "sole",
    },
    {
      label: "합차",
      value: "consol",
    },
    {
      label: "택배",
      value: "parcel",
    },
  ];
export {
  inclusionConditionGroupOptionList,
  containerAccessableRadioGroupOptionList,
  transPortTypeRadioGroupOptionList,
  cfsReceivingRadioGroupOptionList,
  containerStuffingRadioGroupOptionList,
  containerDevanningRadioGroupOptionList,
  inlandTransportTypeRadioGroupOptionList,
  exportCheckpointList,
  importCheckpointList,
  inlandTypeRadioGroupOptions,
};
