import { useFieldArray, useFormContext } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid } from "@mui/material";

import { ADMIN_BID_CONTAINER_TYPE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import { AdminShipmentItemGroupForm } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ContainerSize } from "@sellernote/_shared/src/types/forwarding/bid";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";
import NumericFormatWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/NumericFormatWithReactHookForm";

export default function ContainerList({
  itemGroupListIndex,
  isImport,
}: {
  itemGroupListIndex: number;
  isImport: boolean;
}) {
  const usesMultiContainer = isImport;

  const { control, watch } = useFormContext<{
    itemGroupList: AdminShipmentItemGroupForm[];
  }>();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `itemGroupList.${itemGroupListIndex}.containerInfo`,
  });

  const handleContainerAdd = () => {
    /**
     * 아직 추가되지 않은 container size만 추가할 수 있다.
     * - 수량을 늘리는 식으로 사용되기 때문
     */
    const nextAddableContainerSize = remainedContainerSizeList?.[0];
    if (!nextAddableContainerSize) {
      return;
    }

    append({
      containerType: nextAddableContainerSize,
      quantity: 0,
    });
  };

  const watchedContainerInfo = watch(
    `itemGroupList.${itemGroupListIndex}.containerInfo`
  );
  /**
   * 아직 추가되지 않은 containerSize 타입
   */
  const remainedContainerSizeList: ContainerSize[] = (() => {
    return (
      ADMIN_BID_CONTAINER_TYPE_OPTION_LIST.filter(
        (option) =>
          // watch를 쓰지 않으면 option에 현재 값이 반영 안 되어 watch 상태를 사용함.
          !watchedContainerInfo?.some(
            (field) => field.containerType === option.value
          )
      )?.map((option) => option.value) || []
    );
  })();

  const getOptionDisabled = (option: ContainerSize) => {
    return !remainedContainerSizeList.includes(option);
  };

  return (
    <Grid container spacing={1}>
      {fields.map((field, index) => {
        return (
          <Grid container item spacing={2} key={field.id}>
            <Grid item flex={2} alignItems={"center"}>
              <AutoCompleteWithReactHookForm
                name={`itemGroupList.${itemGroupListIndex}.containerInfo.${index}.containerType`}
                control={control}
                options={ADMIN_BID_CONTAINER_TYPE_OPTION_LIST.map((v) => {
                  return v.value;
                })}
                getOptionDisabled={getOptionDisabled}
                required={true}
              />
            </Grid>

            <Grid item>
              <CloseIcon fontSize="small" sx={{ marginTop: "4px" }} />
            </Grid>

            <Grid item flex={1}>
              <NumericFormatWithReactHookForm
                name={`itemGroupList.${itemGroupListIndex}.containerInfo.${index}.quantity`}
                control={control}
                required={true}
              />
            </Grid>

            {usesMultiContainer && (
              <Grid item>
                <Button
                  disabled={fields.length === 1}
                  color="warning"
                  size="small"
                  onClick={() => remove(index)}
                >
                  <DeleteIcon />
                </Button>
              </Grid>
            )}
          </Grid>
        );
      })}

      {usesMultiContainer && (
        <Grid item>
          <Button
            variant="contained"
            color="warning"
            onClick={handleContainerAdd}
            size="small"
            endIcon={<AddCircleIcon />}
            disabled={!remainedContainerSizeList.length}
          >
            컨테이너 추가
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
