import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Modal, Spin } from "antd";
import { useSetRecoilState } from "recoil";

import { GET_SHIP_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/ship";
import {
  GET_EXPORT_TRELLO_FINISHED_LIST_REQ,
  GET_EXPORT_TRELLO_LIST_REQ,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { PartnerListItem } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  ExportTrelloList,
  FetchTrelloListParams,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { useWindowResize } from "@sellernote/_shared/src/utils/common/hook";
import { checkIsSettlement } from "@sellernote/_shared/src/utils/forwarding/trello";

import useCommentList from "../../../../hooks/useCommentList";

import BasicInfo from "../components/BasicInfo";
import Profit from "../components/Profit";
import PurchaseManagement from "../components/PurchaseManagement";
import SalesManagement from "../components/SalesManagement";
import ShipmentSchedule from "../components/ShipmentSchedule";
import ShipNameManagement from "../components/ShipNameManagement";
import UploadDocuments from "../components/UploadDocuments";

function ExportTrelloDetailModal({
  trelloDetailData,
  shipList,
  exchangeRateList,
  partnerList,
  showsExportTrelloDetailModal,
  setShowsExportTrelloDetailModal,
  trelloListData,
  fetchTrelloListParams,
}: {
  trelloDetailData: TrelloBidDetail | undefined;
  shipList?: GET_SHIP_LIST_RES | undefined;
  exchangeRateList: ExchangeRate[] | undefined;
  partnerList?: PartnerListItem[];
  showsExportTrelloDetailModal: boolean;
  setShowsExportTrelloDetailModal: Dispatch<SetStateAction<boolean>>;
  trelloListData?: ExportTrelloList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) {
  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const queryClient = useQueryClient();

  const history = useHistory();

  const [windowWidth] = useWindowResize();

  const { mutate: confirmShipmentSchedule } =
    TRELLO_BID_QUERY.useConfirmShipmentSchedule();

  const { CommentList } = useCommentList({
    commentList: trelloDetailData?.management.comments,
    dataId: trelloDetailData?.id,
    dataType: "trello",
  });

  useEffect(() => {
    setTrelloCardId(undefined);
  }, [setTrelloCardId]);

  // 모달의 넓이를 최대한 넓게 해달라는 요청 반영
  const modalWidth = windowWidth - 50;

  const handleCloseModal = useCallback(() => {
    setShowsExportTrelloDetailModal(false);

    const { region, corpSizeType, blKind, ...trelloParams } =
      fetchTrelloListParams || {};

    if (trelloListData && trelloDetailData) {
      const trelloListParams = {
        ...trelloParams,
        ...(region === "all" ? [] : { region }),
        ...(corpSizeType === "all" ? [] : { corpSizeType }),
        ...(blKind === "all" ? [] : { blKind }),
        status: "inProgress",
        perPage: 300,
        serviceType:
          trelloDetailData.serviceType === "consolidation"
            ? "consolidation"
            : undefined,
      } as GET_EXPORT_TRELLO_LIST_REQ;

      const finishedTrelloListParams = {
        ...trelloParams,
        ...(region === "all" ? [] : { region }),
        ...(corpSizeType === "all" ? [] : { corpSizeType }),
        ...(blKind === "all" ? [] : { blKind }),
        status: "finished",
        projectStatus: "finished",
        perPage: 200,
        serviceType:
          trelloDetailData.serviceType === "consolidation"
            ? "consolidation"
            : undefined,
      } as GET_EXPORT_TRELLO_FINISHED_LIST_REQ;

      // concat으로 진행중 + 종료로 된 데이터를 다시 분리
      const notFinishedTrelloListData = [...trelloListData].filter((v) => {
        return v.projectStatus !== "finished";
      });

      // 종료 상태인 의뢰인 경우
      if (trelloDetailData?.projectStatus === "finished") {
        // 진행중 리스트에 데이터가 있는지 확인
        const targetTrelloListItem = notFinishedTrelloListData.find((v) => {
          return v.id === trelloDetailData.id;
        });

        if (targetTrelloListItem) {
          // 진행중 => 강제 종료로 변경된 경우 진행중과 종료 둘 다 데이터가 남아있기 때문에 진행중에서는 제거해야함
          const newTrelloListData = notFinishedTrelloListData.filter((v) => {
            return v.id !== trelloDetailData.id;
          });

          const oldQueryData = queryClient.getQueryData(
            TRELLO_BID_QUERY_KEY_GEN.getExportTrelloList({
              ...trelloListParams,
            })
          );

          if (typeof oldQueryData === "object") {
            queryClient.setQueryData(
              TRELLO_BID_QUERY_KEY_GEN.getExportTrelloList({
                ...trelloListParams,
              }),
              { ...oldQueryData, data: newTrelloListData }
            );
          }
        }

        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getExportTrelloFinishedList({
            ...finishedTrelloListParams,
          })
        );

        return;
      }

      // 진행 중 상태인 의뢰인 경우
      const oldQueryData = queryClient.getQueryData(
        TRELLO_BID_QUERY_KEY_GEN.getExportTrelloList({
          ...trelloListParams,
        })
      );

      // 변경한 의뢰번호를 확인해서 인덱스를 찾음
      const targetDataIndex = notFinishedTrelloListData.findIndex((v) => {
        return v.id === trelloDetailData.id;
      });

      // 만약 인덱스를 찾지 못한다면 전체 데이터를 다시 요청(정산 요청을 하게 되는 경우 정산 테이블로 빠지게 되는 경우가 있음) find에서 찾지 못 할 경우 -1를 리턴
      if (targetDataIndex === -1) {
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloBidList());
        return;
      }

      notFinishedTrelloListData[targetDataIndex] = {
        accountPayables: trelloDetailData.accountPayables,
        attachmentFlag:
          notFinishedTrelloListData[targetDataIndex]?.attachmentFlag,
        attachments: notFinishedTrelloListData[targetDataIndex].attachments,
        containCustoms: trelloDetailData.containCustoms,
        endAddress: trelloDetailData.endAddress,
        endAddressDetail: trelloDetailData.endAddressDetail,
        endCountry: trelloDetailData.endCountry,
        invoices: notFinishedTrelloListData[targetDataIndex].invoices,
        freightType: trelloDetailData.freightType,
        id: trelloDetailData.id,
        incoterms: trelloDetailData.incoterms,
        management: {
          ...trelloDetailData.management,
          externalApiUpdatedAt: null,
        },
        poNumber: notFinishedTrelloListData[targetDataIndex].poNumber,
        projectStatus: trelloDetailData.projectStatus,
        serviceType: trelloDetailData.serviceType,
        startAddress: trelloDetailData.startAddress,
        startCountry: trelloDetailData.startCountry,
        team: trelloDetailData.team,
        region: trelloDetailData.locale,
        isImport: trelloDetailData.isImport,
        opCheckPoint: trelloDetailData.opCheckPoint,
      };

      // oldQueryData의 타입이 unknown이라서 스프레드 사용 불가 타입체크를 추가
      if (typeof oldQueryData === "object") {
        queryClient.setQueryData(
          TRELLO_BID_QUERY_KEY_GEN.getExportTrelloList({
            ...trelloListParams,
          }),
          { ...oldQueryData, data: notFinishedTrelloListData }
        );
      }

      /** 상태 변경 칩(유니패스, 마린트래픽, 선사에 의해 변경)이 있는 경우 스케줄 변경 확인 API 호출 */
      if (trelloDetailData?.management.externalApiUpdatedAt) {
        confirmShipmentSchedule(
          { pathParams: { shipmentId: trelloDetailData?.id } },
          {
            onError: () =>
              queryClient.invalidateQueries(
                TRELLO_BID_QUERY_KEY_GEN.trelloBidList()
              ),
          }
        );
      }

      return;
    }

    if (checkIsSettlement(trelloDetailData?.projectStatus)) {
      history.push(`/bid/detail/${trelloDetailData?.id}`);
      return;
    }
  }, [
    confirmShipmentSchedule,
    fetchTrelloListParams,
    history,
    queryClient,
    setShowsExportTrelloDetailModal,
    trelloDetailData,
    trelloListData,
  ]);

  if (!trelloDetailData || !shipList || !exchangeRateList || !partnerList) {
    return (
      <Modal
        destroyOnClose={true}
        style={{ fontFamily: "Roboto" }}
        bodyStyle={{ height: 800, overflow: "scroll" }}
        visible={showsExportTrelloDetailModal}
        width={modalWidth}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Spin />
      </Modal>
    );
  }

  return (
    <Modal
      destroyOnClose={true}
      style={{ fontFamily: "Roboto" }}
      bodyStyle={{ height: 800, overflow: "scroll" }}
      visible={showsExportTrelloDetailModal}
      width={modalWidth}
      onCancel={handleCloseModal}
      footer={null}
    >
      <BasicInfo trelloDetail={trelloDetailData} partnerList={partnerList} />

      <ShipNameManagement trelloDetail={trelloDetailData} />

      <ShipmentSchedule trelloDetail={trelloDetailData} />

      <UploadDocuments trelloDetail={trelloDetailData} />

      <SalesManagement trelloDetailData={trelloDetailData} isExport={true} />

      <PurchaseManagement
        trelloDetailData={trelloDetailData}
        partnerList={partnerList}
        exchangeRateList={exchangeRateList}
      />

      <Profit trelloDetail={trelloDetailData} />

      {CommentList}
    </Modal>
  );
}

export default ExportTrelloDetailModal;
