import { Box, Typography } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";

import ExportCheckPoint from "./ExportCheckpoint";
import ImportCheckPoint from "./ImportCheckPoint";

function CheckPoint({
  freightType,
  isImport,
}: {
  freightType: FreightType;
  isImport: boolean;
}) {
  return (
    <Box>
      <Typography variant="subtitle1" fontWeight={700}>
        체크포인트
      </Typography>

      {isImport ? (
        <ImportCheckPoint freightType={freightType} />
      ) : (
        <ExportCheckPoint freightType={freightType} />
      )}
    </Box>
  );
}

export default CheckPoint;
