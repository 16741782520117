import { useCallback, useMemo } from "react";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  TrelloBidDetail,
  TrelloPartnerRequest,
  TrelloPurchaseManagementTableData,
} from "@sellernote/_shared/src/types/forwarding/trello";

export default function useTrelloPurchaseManagementTableData({
  trelloDetailData,
}: {
  trelloDetailData: TrelloBidDetail;
}) {
  const getPurchaseManagementTableAttachments = useCallback(
    (id: number) => {
      return trelloDetailData.purchaseAttachments.filter((v) => {
        return v.subTargetId === id;
      });
    },
    [trelloDetailData.purchaseAttachments]
  );

  const checkWithdrawalRequest = (request: TrelloPartnerRequest) => {
    if (!request) {
      return false;
    }
    if (!request.isWithdrawalRequested) {
      return false;
    }

    return true;
  };

  const trelloPurchaseManagementTableData: TrelloPurchaseManagementTableData[] =
    useMemo(() => {
      if (APP_NAME === "partner-admin") {
        return trelloDetailData.accountPayables
          .map((v) => {
            return {
              domain: v.domain,
              partnerName: !v.partner ? "-" : v.partner.name,
              isForeignWithdrawalTarget: v.isForeignWithdrawalTarget,
              purchaseRequest: !v.request ? false : true,
              totalPrice: !v.request ? undefined : v.request.totalPrice,
              vatPrice: !v.request ? undefined : v.request.vatPrice,
              finalPrice: !v.request ? undefined : v.request.finalPrice,
              isRequest: checkWithdrawalRequest(v.request),
              isWithdrawn: !v.request ? false : v.request.isWithdrawn,
              partnerId: !v.partner ? null : v.partner.id,
              bidAccountPayableId: v.id,
              attachment: getPurchaseManagementTableAttachments(v.id),
              foreignWithdrawalTargetCompanyId:
                v.foreignWithdrawalTargetCompanyId,
            };
          })
          .filter((n) => {
            return n.domain === "customs" || n.domain === "customsDuty";
          });
      }

      return trelloDetailData.accountPayables.map((v) => {
        return {
          domain: v.domain,
          partnerName: !v.partner ? "-" : v.partner.name,
          isForeignWithdrawalTarget: v.isForeignWithdrawalTarget,
          purchaseRequest: !v.request ? false : true,
          totalPrice: !v.request ? undefined : v.request.totalPrice,
          vatPrice: !v.request ? undefined : v.request.vatPrice,
          finalPrice: !v.request ? undefined : v.request.finalPrice,
          isRequest: checkWithdrawalRequest(v.request),
          isWithdrawn: !v.request ? false : v.request.isWithdrawn,
          withdrawalDate: v.request ? v.request.withdrawalDate : null,
          partnerId: !v.partner ? null : v.partner.id,
          bidAccountPayableId: v.id,
          attachment: getPurchaseManagementTableAttachments(v.id),
          foreignWithdrawalTargetCompanyId: v.foreignWithdrawalTargetCompanyId,
        };
      });
    }, [
      getPurchaseManagementTableAttachments,
      trelloDetailData.accountPayables,
    ]);

  return { trelloPurchaseManagementTableData };
}
